.spin{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000020;
}

.ant-message{
  z-index: 10000100;
}
.ant-btn-primary{
  border-color: #2D9CDB;
  background: #2D9CDB;
}

.ant-avatar > img{
  display: inherit;
  height: auto;
}