@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root, .ant-layout{
  width:100%;
  height:100%;
}

div, h1, h2, h3, h4, h5, h6, p, input, select, textarea, span, strong{
  font-family: 'Lato', sans-serif !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

..ant-modal-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal{
  top:0 !important;
}

/* .ant-modal-root, .ant-modal-wrap{
  z-index:10000000 !important;
} */

.ant-dropdown .ant-dropdown-menu-item{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #303030;
}
.ant-dropdown .ant-dropdown-menu-item span{
  margin:0px 10px;
}

.ant-dropdown .view-more .ant-dropdown-menu-item span{
  margin:0px 0px;
  
}

.ant-dropdown .view-more .ant-dropdown-menu-item label{
  margin-right:10px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.ant-modal-confirm .ant-modal-confirm-btns{
  float: left !important
}


.ant-popover-content .ant-popover-inner-content .ant-col.view-more-item{
  margin-bottom: 10px;
}
.spin{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000020;
}

.ant-message{
  z-index: 10000100;
}
.ant-btn-primary{
  border-color: #2D9CDB;
  background: #2D9CDB;
}

.ant-avatar > img{
  display: inherit;
  height: auto;
}
